import React from 'react';
import { Edit, SimpleForm, TextInput, ReferenceInput, SelectInput, DateTimeInput } from 'react-admin';
import { formatToUTC, formatFromUTCToInput } from '../utils/dateUtils';

export const TerminvorschlagEdit = (props: any) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput disabled source="id" />
            <ReferenceInput source="bestellung_id" reference="bestellung">
                <SelectInput optionText="id" />
            </ReferenceInput>
            <GermanDateTimeInput source="vorschlagdatetime" />
            <SelectInput source="status" choices={[
                { id: 'vorgeschlagen', name: 'Vorgeschlagen' },
                { id: 'akzeptiert', name: 'Akzeptiert' },
                { id: 'abgelehnt', name: 'Abgelehnt' },
            ]} />
        </SimpleForm>
    </Edit>
);

const GermanDateTimeInput = (props: any) => {
    const transform = (value: string) => {
        if (!value) return '';
        return formatToUTC(value);
    };

    const parse = (value: string) => {
        if (!value) return '';
        return formatFromUTCToInput(value);
    };

    return (
        <DateTimeInput 
            {...props}
            transform={transform}
            format={parse}
            options={{
                format: 'DD.MM.YYYY HH:mm',
                ampm: false,
            }}
        />
    );
};