import React from 'react';
import { useRecordContext, useDataProvider, useRefresh, Button, useResourceContext } from 'react-admin';
import ArchiveIcon from '@mui/icons-material/Archive';

const ArchiveButton = ({ resource = 'produkt' }) => {
    const record = useRecordContext();
    const dataProvider = useDataProvider();
    const refresh = useRefresh();

    if (!record) return null;

    const handleClick = async (e: React.MouseEvent) => {
        e.stopPropagation();
        try {
            await dataProvider.update(resource, {
                id: record.id,
                data: { archived: true },
                previousData: record,
            });
            refresh();
        } catch (error) {
            console.error(`Error archiving ${resource}:`, error);
        }
    };

    return (
        <Button
            label="Archivieren"
            onClick={handleClick}
            color="warning"
        >
            <ArchiveIcon />
        </Button>
    );
};

export default ArchiveButton;