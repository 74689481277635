// App.tsx
import React from 'react';
import { Admin, Resource, CustomRoutes } from 'react-admin';
import { MitarbeiterList } from './components/MitarbeiterList';
import { MitarbeiterEdit } from './components/MitarbeiterEdit';
import { MitarbeiterCreate } from './components/MitarbeiterCreate';
import { MuseumList } from './components/MuseumList';
import { MuseumEdit } from './components/MuseumEdit';
import { MuseumCreate } from './components/MuseumCreate';
import { ProduktList } from './components/ProduktList';
import { ProduktEdit } from './components/ProduktEdit';
import { ProduktCreate } from './components/ProduktCreate';
import { KundeList } from './components/KundeList';
import { KundeEdit } from './components/KundeEdit';
import { KundeCreate } from './components/KundeCreate';
import { BestellungList } from './components/BestellungList';
import { BestellungEdit } from './components/BestellungEdit';
import { BestellungCreate } from './components/BestellungCreate';
import { TerminvorschlagList } from './components/TerminvorschlagList';
import { TerminvorschlagEdit } from './components/TerminvorschlagEdit';
import { TerminvorschlagCreate } from './components/TerminvorschlagCreate';
import { BlockierungList } from './components/BlockierungList';
import { BlockierungEdit } from './components/BlockierungEdit';
import { BlockierungCreate } from './components/BlockierungCreate';
import { ArchivedProduktList } from './components/ArchivedProduktList';

import NeueBestellungWizard from './components/NeueBestellungWizard';
import TerminKalender from './components/TerminKalender';
import EmployeeTerminKalender from './components/EmployeeTerminKalender';
import dataProvider from './dataProvider';
import { CustomLayout } from './CustomLayout';
import { Route } from 'react-router-dom';
import authProvider from './authProvider';
import LoginPage from './components/LoginPage';
import Dashboard from './Dashboard';
import { ArchivedBestellungList } from './components/ArchivedBestellungList';

const App = () => (
  <Admin
    dataProvider={dataProvider}
    authProvider={authProvider}
    layout={CustomLayout}
    loginPage={LoginPage}
    dashboard={Dashboard}
  >
    {(permissions) => (
      <>
        {permissions === 'admin' && (
          <>
            <Resource
              name="mitarbeiter"
              list={MitarbeiterList}
              edit={MitarbeiterEdit}
              create={MitarbeiterCreate}
            />
            <Resource
              name="museum"
              list={MuseumList}
              edit={MuseumEdit}
              create={MuseumCreate}
            />
            <Resource
              name="produkt"
              list={ProduktList}
              edit={ProduktEdit}
              create={ProduktCreate}
            />
            <Resource
              name="produktarchiv"
              list={ArchivedProduktList}
              options={{ 
                label: 'Archivierte Produkte',
                route: 'archived-produkte'
              }}
            />
            <Resource
              name="kunde"
              list={KundeList}
              edit={KundeEdit}
              create={KundeCreate}
            />
            <Resource
              name="bestellung"
              list={BestellungList}
              edit={BestellungEdit}
              create={BestellungCreate}
            />
            <Resource
              name="archived-bestellungen"
              list={ArchivedBestellungList}
              options={{ label: 'Archivierte Bestellungen' }}
            />
            <Resource
              name="terminvorschlag"
              list={TerminvorschlagList}
              edit={TerminvorschlagEdit}
              create={TerminvorschlagCreate}
            />
            <Resource
              name="blockierung"
              list={BlockierungList}
              edit={BlockierungEdit}
              create={BlockierungCreate}
            />
            <CustomRoutes>
              <Route path="/neue-bestellung" element={<NeueBestellungWizard />} />
              <Route path="/termin-kalender" element={<TerminKalender />} />
            </CustomRoutes>
          </>
        )}
        {permissions !== 'admin' && (
          <CustomRoutes>
            <Route
              path="/mitarbeiter-kalender"
              element={<EmployeeTerminKalender />}
            />
          </CustomRoutes>
        )}
      </>
    )}
  </Admin>
);

export default App;