import React, { useState, useEffect } from 'react';
import { useDataProvider, useGetIdentity } from 'react-admin';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment-timezone';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, List, ListItem, ListItemText, CircularProgress, Typography, Paper, Box } from '@mui/material';

moment.locale('de', {
  week: {
    dow: 1,
    doy: 4
  }
});

const localizer = momentLocalizer(moment);

interface TerminEvent {
  title: string;
  start: Date;
  end: Date;
  status: string;
  color: string;
  terminvorschlagId: number;
  bestellungId: number;
  isBlockierung: false;
}

interface BlockierungEvent {
  title: string;
  start: Date;
  end: Date;
  status: string;
  color: string;
  isBlockierung: true;
  grund?: string;
}

type CalendarEvent = TerminEvent | BlockierungEvent;

interface TerminDetails {
  kunde: {
    einrichtung: string;
    ansprechpartner: string;
    email: string;
    telefon: string;
  };
  anzahltn: number;
  sonderwuensche: string;
}

const EmployeeTerminKalender = () => {
  const [events, setEvents] = useState<CalendarEvent[]>([]);
  const [upcomingPersonalEvents, setUpcomingPersonalEvents] = useState<CalendarEvent[]>([]);
  const [selectedEvent, setSelectedEvent] = useState<CalendarEvent | null>(null);
  const [terminDetails, setTerminDetails] = useState<TerminDetails | null>(null);
  const [coWorkers, setCoWorkers] = useState<string[]>([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [employeeName, setEmployeeName] = useState('');
  const dataProvider = useDataProvider();
  const { identity, isLoading: isIdentityLoading } = useGetIdentity();
  const [mitarbeiterTerminIds, setMitarbeiterTerminIds] = useState<Set<number>>(new Set());

  useEffect(() => {
    const fetchEvents = async () => {
      if (isIdentityLoading || !identity?.id) return;

      try {
        setIsLoading(true);
        
        // Fetch employee details using auth_user_id
        const { data: mitarbeiterList } = await dataProvider.getList('mitarbeiter', {
          pagination: { page: 1, perPage: 1 },
          sort: { field: 'id', order: 'ASC' },
          filter: { auth_user_id: identity.id },
        });

        if (mitarbeiterList.length === 0) {
          console.error('No mitarbeiter found for this user');
          setIsLoading(false);
          return;
        }

        const mitarbeiter = mitarbeiterList[0];
        setEmployeeName(`${mitarbeiter.vorname} ${mitarbeiter.nachname}`);

        // Fetch all data in parallel
        const [
          { data: termine },
          { data: blockierungen },
          { data: bestellungen },
          { data: produkte },
          { data: museen }
        ] = await Promise.all([
          dataProvider.getList('terminvorschlag', {
            pagination: { page: 1, perPage: 1000 },
            sort: { field: 'vorschlagdatetime', order: 'ASC' },
            filter: { status: 'akzeptiert' },
          }),
          dataProvider.getList('blockierung', {
            pagination: { page: 1, perPage: 1000 },
            sort: { field: 'start', order: 'ASC' },
          }),
          dataProvider.getList('bestellung', {
            pagination: { page: 1, perPage: 1000 },
          }),
          dataProvider.getList('produkt', {
            pagination: { page: 1, perPage: 1000 },
          }),
          dataProvider.getList('museum', {
            pagination: { page: 1, perPage: 1000 },
          }),
        ]);

        // Create lookup maps for faster access
        const bestellungenMap = new Map(bestellungen.map(b => [b.id, b]));
        const produkteMap = new Map(produkte.map(p => [p.id, p]));
        const museenMap = new Map(museen.map(m => [m.id, m]));

        // Process termine
        const termineEvents = termine
          .map(termin => {
            const bestellung = bestellungenMap.get(termin.bestellung_id);
            if (!bestellung || bestellung.status === 'storniert') return null;

            const produkt = produkteMap.get(bestellung.produkt_id);
            if (!produkt) return null;

            const museum = museenMap.get(produkt.museum_id);
            if (!museum) return null;

            const startTime = moment.utc(termin.vorschlagdatetime).local().toDate();
            const endTime = moment.utc(termin.vorschlagdatetime).local().add(produkt.dauer, 'hours').toDate();

            return {
              title: `${produkt.name} - ${museum.name}`,
              start: startTime,
              end: endTime,
              status: termin.status,
              color: museum.color || '#3174ad',
              terminvorschlagId: termin.id,
              bestellungId: bestellung.id,
              isBlockierung: false as const,
            };
          })
          .filter((event): event is TerminEvent => event !== null);

        // Process blockierungen
        const blockierungenEvents: BlockierungEvent[] = blockierungen.map(blockierung => {
          const museum = museenMap.get(blockierung.museum_id);
          return {
            title: `Blockierung: ${blockierung.name}`,
            start: moment.utc(blockierung.start).local().toDate(),
            end: moment.utc(blockierung.ende).local().toDate(),
            status: 'blockierung',
            color: 'red',
            isBlockierung: true as const,
            grund: blockierung.grund,
          };
        });

        // For the upcoming appointments list, filter only the employee's appointments
        const { data: relMitarbeiterStatus } = await dataProvider.getList('relmitarbeiterstatus', {
          pagination: { page: 1, perPage: 1000 },
          sort: { field: 'id', order: 'ASC' },
          filter: { mitarbeiter_id: mitarbeiter.id, angenommen: true },
        });

        const mitarbeiterIds = new Set(relMitarbeiterStatus.map(rel => rel.terminvorschlag_id));
        setMitarbeiterTerminIds(mitarbeiterIds);

        const personalEvents = termineEvents.filter(event => 
          mitarbeiterIds.has(event.terminvorschlagId)
        );

        // Set all events for the calendar
        setEvents([...termineEvents, ...blockierungenEvents]);
        
        // Store personal events separately for the list below
        setUpcomingPersonalEvents(personalEvents);

      } catch (error) {
        console.error('Error fetching events:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchEvents();
  }, [dataProvider, identity, isIdentityLoading]);

  const eventStyleGetter = (event: CalendarEvent) => {
    if (event.isBlockierung) {
      return {
        style: {
          backgroundColor: 'red',
          color: 'white',
          borderRadius: '0px',
          opacity: 0.8,
          border: 'none',
          display: 'block'
        }
      };
    }

    // Check if this is a personal event
    const isPersonalEvent = mitarbeiterTerminIds.has(event.terminvorschlagId);

    return {
      style: {
        backgroundColor: isPersonalEvent ? '#28a745' : '#808080', // green for personal, gray for others
        opacity: 0.8,
        border: 'none',
        color: 'white'
      }
    };
  };

  const handleEventClick = async (event: CalendarEvent) => {
    setSelectedEvent(event);

    if (event.isBlockierung) {
      setIsDialogOpen(true);
      return;
    }

    setIsLoading(true);

    try {
      // Fetch co-workers
      const { data: relMitarbeiterStatus } = await dataProvider.getList('relmitarbeiterstatus', {
        pagination: { page: 1, perPage: 1000 },
        sort: { field: 'id', order: 'ASC' },
        filter: { terminvorschlag_id: event.terminvorschlagId, angenommen: true },
      });

      const coWorkerPromises = relMitarbeiterStatus.map(async (rel) => {
        const { data: mitarbeiter } = await dataProvider.getOne('mitarbeiter', { id: rel.mitarbeiter_id });
        return `${mitarbeiter.vorname} ${mitarbeiter.nachname}`;
      });

      const coWorkerNames = await Promise.all(coWorkerPromises);
      setCoWorkers(coWorkerNames);

      // Fetch bestellung details
      const { data: bestellung } = await dataProvider.getOne('bestellung', { id: event.bestellungId });
      
      // Fetch kunde details
      const { data: kunde } = await dataProvider.getOne('kunde', { id: bestellung.kunde_id });

      setTerminDetails({
        kunde: {
          einrichtung: kunde.einrichtung,
          ansprechpartner: kunde.ansprechpartner,
          email: kunde.email,
          telefon: kunde.telefon,
        },
        anzahltn: bestellung.anzahltn,
        sonderwuensche: bestellung.sonderwuensche,
      });

      setIsDialogOpen(true);
    } catch (error) {
      console.error('Error loading event details:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setSelectedEvent(null);
    setCoWorkers([]);
    setTerminDetails(null);
  };

  if (isLoading || isIdentityLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </div>
    );
  }

  const upcomingAppointments = upcomingPersonalEvents
    .filter(event => event.start > new Date())
    .sort((a, b) => a.start.getTime() - b.start.getTime())
    .slice(0, 5);

  return (
    <div style={{ padding: '20px' }}>
      <Typography variant="h4" 
        gutterBottom
        sx={{
          fontSize: {
            xs: '1.5rem',
            sm: '2.125rem'
          }
        }}
      >
        Hallo {employeeName}!
      </Typography>
      
      <div style={{ 
        display: 'flex', 
        gap: '20px',
        flexWrap: 'wrap'
      }}>
        {/* Left side - Upcoming appointments */}
        <Paper elevation={3} sx={{ 
          flex: '1 1 30%',
          minWidth: {
            xs: '100%',  // Full width on mobile
            sm: '280px',
            md: '300px'
          },
          maxWidth: {
            xs: '100%',  // Full width on mobile
            sm: '400px'  // Max width on larger screens
          },
          padding: {
            xs: '10px',
            sm: '20px'
          },
          height: 'fit-content'
        }}>
          <Typography variant="h6" 
            gutterBottom
            sx={{
              fontSize: {
                xs: '1.1rem',
                sm: '1.25rem'
              }
            }}
          >
            Deine nächsten Termine:
          </Typography>
          {upcomingAppointments.length > 0 ? (
            <List sx={{
              '& .MuiListItem-root': {
                padding: {
                  xs: '8px',
                  sm: '16px'
                }
              }
            }}>
              {upcomingAppointments.map((event, index) => (
                <ListItem 
                  key={index} 
                  button 
                  onClick={() => handleEventClick(event)}
                  sx={{
                    cursor: 'pointer',
                    '& .MuiListItemText-primary': {
                      fontSize: {
                        xs: '0.9rem',
                        sm: '1rem'
                      }
                    },
                    '& .MuiListItemText-secondary': {
                      fontSize: {
                        xs: '0.8rem',
                        sm: '0.875rem'
                      }
                    }
                  }}
                >
                  <ListItemText
                    primary={event.title}
                    secondary={`${moment(event.start).format('DD.MM.YYYY HH:mm')} - ${moment(event.end).format('HH:mm')}`}
                  />
                </ListItem>
              ))}
            </List>
          ) : (
            <Typography>Keine anstehenden Termine.</Typography>
          )}
        </Paper>

        {/* Right side - Calendar */}
        <Box sx={{ 
          flex: '1 1 65%',
          minWidth: {
            xs: '280px',
            sm: '280px',
            md: '300px'
          },
          height: {
            xs: '400px',
            sm: '500px',
            md: '600px'
          }
        }}>
          <Calendar
            localizer={localizer}
            events={events}
            startAccessor="start"
            endAccessor="end"
            eventPropGetter={eventStyleGetter}
            onSelectEvent={handleEventClick}
            views={['month', 'week', 'day']}
            formats={{
              timeGutterFormat: 'HH:mm',
              eventTimeRangeFormat: ({ start, end }: { start: Date; end: Date }) => (
                `${moment(start).format('HH:mm')} - ${moment(end).format('HH:mm')}`
              ),
            }}
            messages={{
              today: 'Heute',
              previous: 'Zurück',
              next: 'Vor',
              month: 'Monat',
              week: 'Woche',
              day: 'Tag'
            }}
            style={{ height: '100%' }}
          />
        </Box>
      </div>

      <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>{selectedEvent?.title}</DialogTitle>
        <DialogContent>
          <Typography>Beginn: {moment(selectedEvent?.start).format('MMMM Do YYYY, HH:mm')}</Typography>
          <Typography>Ende: {moment(selectedEvent?.end).format('MMMM Do YYYY, HH:mm')}</Typography>
          
          {selectedEvent?.isBlockierung ? (
            <Typography style={{ marginTop: '16px' }}>Grund: {selectedEvent.grund}</Typography>
          ) : (
            terminDetails && (
              <>
                <Typography variant="subtitle1" style={{ marginTop: '16px' }}>Kunde:</Typography>
                <Typography>Einrichtung: {terminDetails.kunde.einrichtung}</Typography>
                <Typography>Ansprechpartner:in: {terminDetails.kunde.ansprechpartner}</Typography>
                <Typography>Email: {terminDetails.kunde.email}</Typography>
                <Typography>Telefon: {terminDetails.kunde.telefon}</Typography>
                
                <Typography variant="subtitle1" style={{ marginTop: '16px' }}>Weitere Details:</Typography>
                <Typography>Anzahl Teilnehmende: {terminDetails.anzahltn}</Typography>
                <Typography>Sonderwünsche: {terminDetails.sonderwuensche || 'Keine'}</Typography>

                <Typography variant="subtitle1" style={{ marginTop: '16px' }}>Team:</Typography>
                <List>
                  {coWorkers.map((worker, index) => (
                    <ListItem key={index}>
                      <ListItemText primary={worker} />
                    </ListItem>
                  ))}
                </List>
              </>
            )
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Schliessen</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EmployeeTerminKalender;