import React from 'react';
import { Datagrid, TextField, DatagridProps, useRecordContext } from 'react-admin';
import ZugewieseneMitarbeiter from './ZugewieseneMitarbeiter';
import { formatDateTime } from '../utils/dateUtils';

interface TerminvorschlagRecord {
    id: number;
    status: string;
    vorschlagdatetime: string;
}

const rowStyle = (record: TerminvorschlagRecord): React.CSSProperties => {
    if (!record) return {};
    switch (record.status) {
        case 'match':
            return { backgroundColor: 'lightgreen' };
        case 'akzeptiert':
            return { backgroundColor: 'lightblue' };
        default:
            return {};
    }
};

interface LocalDateFieldProps {
    source: string;
}

const LocalDateField: React.FC<LocalDateFieldProps> = ({ source }) => {
    const record = useRecordContext();
    if (!record || !record[source]) return null;
    return <span>{formatDateTime(record[source])}</span>;
};

const TerminvorschlagDatagrid: React.FC<DatagridProps> = (props) => (
    <Datagrid {...props} rowClick="edit" rowStyle={rowStyle}>
        <TextField source="id" />
        <LocalDateField source="vorschlagdatetime" />
        <TextField source="status" />
        <ZugewieseneMitarbeiter />
    </Datagrid>
);

export default TerminvorschlagDatagrid;