// sendEmail.ts
import supabase from './supabaseClient';
import { DataProvider } from 'react-admin';

import moment from 'moment-timezone';

moment.tz.setDefault("Europe/Berlin");



export const sendConfirmationEmail = async (
  dataProvider: DataProvider,
  notify: any,
  mitarbeiterId: number,
  terminvorschlagId: number
) => {
  // Declare variables to hold necessary information
  let mitarbeiterEmail = '';
  let bestellungId: number | null = null;

  try {
    // Fetch necessary data
    const [{ data: mitarbeiter }, { data: terminvorschlag }] = await Promise.all([
      dataProvider.getOne('mitarbeiter', { id: mitarbeiterId }),
      dataProvider.getOne('terminvorschlag', { id: terminvorschlagId }),
    ]);

    if (!mitarbeiter || !terminvorschlag) {
      throw new Error('Required data not found');
    }

    mitarbeiterEmail = mitarbeiter.email;

    // Fetch bestellung
    const { data: bestellung } = await dataProvider.getOne('bestellung', { id: terminvorschlag.bestellung_id });
    if (!bestellung) {
      throw new Error('Bestellung not found');
    }

    bestellungId = bestellung.id;

    // Fetch produkt and kunde
    const [{ data: produkt }, { data: kunde }] = await Promise.all([
      dataProvider.getOne('produkt', { id: bestellung.produkt_id }),
      dataProvider.getOne('kunde', { id: bestellung.kunde_id }),
    ]);

    if (!produkt || !kunde) {
      throw new Error('Required data not found');
    }

    // Convert UTC to local time
    const localDateTime = moment.utc(terminvorschlag.vorschlagdatetime).local().format('DD.MM.YYYY HH:mm:ss');



    // Prepare email content with all the fetched details
    const emailContent = `
    <h1>Bestätigung der Termin-Zuweisung</h1>
    <p>Hallo ${mitarbeiter.vorname} ${mitarbeiter.nachname},</p>
    <p>Gerne bestätigen wir dir den folgenden Auftrag:</p>
    <h2>Termindetails:</h2>
    <ul>
        <li>Datum und Uhrzeit: ${localDateTime}</li>
        <li>Status: ${terminvorschlag.status}</li>
    </ul>
    <h2>Produktdetails:</h2>
    <ul>
        <li>Produktname: ${produkt.name}</li>
        <li>Dauer: ${produkt.dauer} Stunden</li>
        <li>Maximale Teilnehmerzahl: ${produkt.maxtn}</li>
    </ul>
    <h2>Bestellungsdetails:</h2>
    <ul>
        <li>Bestellungs-ID: ${bestellung.id}</li>
        <li>Anzahl Teilnehmer: ${bestellung.anzahltn}</li>
        <li>Sonderwünsche: ${bestellung.sonderwuensche || 'Keine'}</li>
        <li>Status: ${bestellung.status}</li>
    </ul>
    <h2>Kundendetails:</h2>
    <ul>
        <li>Einrichtung: ${kunde.einrichtung}</li>
        <li>Ansprechpartner: ${kunde.ansprechpartner}</li>
        <li>Adresse: ${kunde.adresse}, ${kunde.plz} ${kunde.ort}</li>
        <li>Telefon: ${kunde.telefon}</li>
        <li>E-Mail: ${kunde.email}</li>
    </ul>

    <p>In wenigen Minuten erhältst du eine automatisch generierte E-Mail mit einem Link zur Unterzeichnung des Einzelauftrags</p>
    
`;


    // Send email via Supabase Edge Function
    const { error } = await supabase.functions.invoke('send-email', {
      body: {
        to: mitarbeiter.email,
        subject: `Terminbestätigung: ${produkt.name} am ${localDateTime}`,
        html: emailContent,
      },
    });

    console.log(mitarbeiter.email)
    

    if (error) throw error;

    notify('Confirmation email sent', { type: 'info' });
  } catch (error) {
    console.error('Error sending confirmation email:', error);
    notify('Error sending confirmation email', { type: 'error' });

    // Insert into failed_emails table
    const errorMessage = error instanceof Error ? error.message : 'Unknown error';
    await supabase.from('failed_emails').insert([{
      bestellung_id: bestellungId,
      recipient_email: mitarbeiterEmail,
      error_message: errorMessage,
      // The rest of the fields use default values
    }]);
  }
};


export const sendCustomerConfirmationEmail = async (
  dataProvider: DataProvider,
  notify: any,
  kunde: any,
  terminvorschlag: any,
  produkt: any,
  acceptedEmployees: any[]
) => {
  try {
    // Convert UTC to local time
    const startTime = moment.utc(terminvorschlag.vorschlagdatetime).local();
    const endTime = startTime.clone().add(produkt.dauer, 'hours');
    const formattedStartTime = startTime.format('DD.MM.YYYY HH:mm');
    const formattedEndTime = endTime.format('HH:mm');

    // Fetch museum details
    const { data: museum } = await dataProvider.getOne('museum', { id: produkt.museum_id });

    const employeeInfo = await Promise.all(acceptedEmployees.map(async (emp) => {
      const { data: mitarbeiter } = await dataProvider.getOne('mitarbeiter', { id: emp.mitarbeiter_id });
      return `<li><strong>${mitarbeiter.vorname} ${mitarbeiter.nachname}</strong>, ${mitarbeiter.email}, ${mitarbeiter.mobil}</li>`;
    }));

    const emailContent = `
    <p>Sehr geehrte ${kunde.ansprechpartner},</p>

    <p><strong>Treffpunkt:</strong> ${museum.name}, ${museum.adresse}, ${museum.plz} ${museum.ort}<br>
    <strong>Datum:</strong> ${formattedStartTime}<br>
    <strong>Zeit:</strong> ${formattedStartTime} bis ${formattedEndTime}<br>
    <strong>Kosten:</strong> ${produkt.kosten} €/Kind, bitte bar und passend mitbringen. Eine Quittung wird vor Ort erstellt.</p>

    <p><strong>Museumspädagogi:innen:</strong></p>
    <ul>
    ${employeeInfo.join('')}
    </ul>

    <p><strong>Wichtige Hinweise:</strong></p>
    <p>Bitte beachten Sie, dass eine <strong>kostenfreie Änderungs- und Stornierungsmöglichkeit nur bis drei Werktage vor dem jeweiligen Termin</strong> besteht. Bei einer rechtzeitigen Stornierung müssen wir Ihnen den Verwaltungsaufwand von 30 € in Rechnung stellen. Erfolgt die Stornierung zu kurzfristig (weniger als drei Werktage) berechnen wir Ihnen unsere Unkosten in Höhe von 70 € pro gebuchter Stunde.</p>

    <p>Im Fall einer Verspätung bitten wir Sie, unter o. g. Handy-Nummern Bescheid zu geben. Die Museumspädagog:in wartet bis 15 Minuten nach dem vereinbarten Termin, so dass sich die Dauer der Veranstaltung gegebenenfalls entsprechend verkürzt. Wird die Veranstaltung nicht wahrgenommen, entfällt die vereinbarte Leistung, und wir müssen die Unkosten ebenfalls (s. o.) in Rechnung stellen.</p>

    <p><strong>Bei Absage oder Verlegung des Termins</strong> bitten wir Sie um rechtzeitige Nachricht per Mail an <a href="mailto:theresa.heyer@pforzheim.de">theresa.heyer@pforzheim.de</a> oder rufen Sie uns unter Tel. 07231 / 39 2079 an.</p>

    <p>Mit freundlichen Grüßen<br>
    Theresa Heyer</p>
    `;

    // Send email via Supabase Edge Function
    const { error } = await supabase.functions.invoke('send-email', {
      body: {
        to: kunde.email,
        subject: `Buchungsbestätigung: ${produkt.name} am ${formattedStartTime}`,
        html: emailContent,
      },
    });

    if (error) throw error;

    notify('Bestätigung and Kunden versandt', { type: 'success' });
  } catch (error) {
    console.error('Error sending customer confirmation email:', error);
    notify('Error sending customer confirmation email', { type: 'error' });

    // Insert into failed_emails table
    const errorMessage = error instanceof Error ? error.message : 'Unknown error';
    await supabase.from('failed_emails').insert([{
      bestellung_id: terminvorschlag.bestellung_id,
      recipient_email: kunde.email,
      error_message: errorMessage,
      // The rest of the fields use default values
    }]);
  }
};

export const sendRejectionEmail = async (
  dataProvider: DataProvider,
  notify: any,
  mitarbeiterId: number,
  terminvorschlagId: number
): Promise<void> => {
  // Declare variables to hold necessary information
  let mitarbeiterEmail = '';
  let bestellungId: number | null = null;

  try {
    // Fetch necessary data
    const [{ data: mitarbeiter }, { data: terminvorschlag }] = await Promise.all([
      dataProvider.getOne('mitarbeiter', { id: mitarbeiterId }),
      dataProvider.getOne('terminvorschlag', { id: terminvorschlagId }),
    ]);

    if (!mitarbeiter || !terminvorschlag) {
      throw new Error('Required data not found');
    }

    mitarbeiterEmail = mitarbeiter.email;

    // Fetch bestellung
    const { data: bestellung } = await dataProvider.getOne('bestellung', { id: terminvorschlag.bestellung_id });
    if (!bestellung) {
      throw new Error('Bestellung not found');
    }

    bestellungId = bestellung.id;

    // Fetch produkt and kunde
    const [{ data: produkt }, { data: kunde }] = await Promise.all([
      dataProvider.getOne('produkt', { id: bestellung.produkt_id }),
      dataProvider.getOne('kunde', { id: bestellung.kunde_id }),
    ]);

    if (!produkt || !kunde) {
      throw new Error('Required data not found');
    }

    // Convert UTC to local time
    const localDateTime = moment.utc(terminvorschlag.vorschlagdatetime).local().format('DD.MM.YYYY HH:mm:ss');

    // Prepare email content
    const emailContent = `
    <h1>Buchung nicht bestätigt</h1>
    <p>Hallo ${mitarbeiter.vorname} ${mitarbeiter.nachname},</p>
    <p>Die folgende Buchung wurde anderweitig besetzt und du kannst den Termin freigeben:</p>
    <h2>Details:</h2>
    <ul>
      <li>Format: ${produkt.name}</li>
      <li>Dauer: ${produkt.dauer} Stunden</li>
      <li>Datum und Uhrzeit: ${localDateTime}</li>
    </ul>
    <h2>Bestellungsdetails:</h2>
    <ul>
      <li>Bestellungs-ID: ${bestellung.id}</li>
      <li>Anzahl Teilnehmer: ${bestellung.anzahltn}</li>
      <li>Sonderwünsche: ${bestellung.sonderwuensche || 'Keine'}</li>
      <li>Status: ${bestellung.status}</li>
    </ul>
    <h3>Kundendetails:</h3>
    <ul>
      <li>Einrichtung: ${kunde.einrichtung}</li>
      <li>Ansprechpartner:in: ${kunde.ansprechpartner}</li>
      <li>Adresse: ${kunde.adresse}, ${kunde.plz} ${kunde.ort}</li>
      <li>Telefon: ${kunde.telefon}</li>
      <li>E-Mail: ${kunde.email}</li>
    </ul>
    <p>Vielen Dank für deine Bereitschaft.</p>
    `;

    // Send email via Supabase Edge Function
    const { error } = await supabase.functions.invoke('send-email', {
      body: {
        to: mitarbeiterEmail,
        subject: `Buchung nicht bestätigt: ${produkt.name} am ${localDateTime}`,
        html: emailContent,
      },
    });

    if (error) throw error;

    notify('Rejection email sent', { type: 'info' });
  } catch (error) {
    console.error('Error sending rejection email:', error);
    notify('Error sending rejection email', { type: 'error' });

    // Insert into failed_emails table
    const errorMessage = error instanceof Error ? error.message : 'Unknown error';
    await supabase.from('failed_emails').insert([{
      bestellung_id: bestellungId,
      recipient_email: mitarbeiterEmail,
      error_message: errorMessage,
      // The rest of the fields use default values
    }]);
  }
};


const generateSimpleToken = async (terminId: number, mitarbeiterId: number): Promise<string> => {
  const message = `${terminId}:${mitarbeiterId}:${process.env.REACT_APP_TOKEN_SECRET}`;
  const encoder = new TextEncoder();
  const data = encoder.encode(message);
  const hashBuffer = await crypto.subtle.digest('SHA-256', data);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  return hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
};

export const sendEmailToMitarbeiter = async (
  dataProvider: DataProvider,
  notify: (message: string, options?: any) => void,
  mitarbeiterId: number,
  bestellungDetails: string,
  terminvorschlaege: any[]
): Promise<void> => {
  // Declare variables to hold necessary information
  let mitarbeiterEmail = '';
  let bestellungId: number | null = null;

  try {
    // Fetch mitarbeiter details
    const { data: mitarbeiter } = await dataProvider.getOne('mitarbeiter', { id: mitarbeiterId });

    if (!mitarbeiter) {
      throw new Error('Mitarbeiter not found');
    }

    mitarbeiterEmail = mitarbeiter.email;

    // Fetch additional details
    const bestellungId = terminvorschlaege[0].bestellung_id;
    const [{ data: bestellung }, { data: produkt }, { data: kunde }] = await Promise.all([
      dataProvider.getOne('bestellung', { id: bestellungId }),
      dataProvider.getOne('bestellung', { id: bestellungId }).then(res => 
        dataProvider.getOne('produkt', { id: res.data.produkt_id })
      ),
      dataProvider.getOne('bestellung', { id: bestellungId }).then(res => 
        dataProvider.getOne('kunde', { id: res.data.kunde_id })
      ),
    ]);

    if (!bestellung || !produkt || !kunde) {
      throw new Error('Required data not found');
    }

    // Prepare email content with accept/decline buttons for each terminvorschlag
    const terminButtons = await Promise.all(terminvorschlaege.map(async (termin) => {
      const token = await generateSimpleToken(termin.id, mitarbeiterId);
      
      // Convert UTC time to local time
      const localDateTime = moment.utc(termin.vorschlagdatetime).local().format('DD.MM.YYYY HH:mm:ss');
      
      return `
        <div>
          <p>Vorschlag: ${localDateTime}</p>
          <a href="${process.env.REACT_APP_SUPABASE_URL}/functions/v1/respond-to-termin?action=accept&terminId=${termin.id}&mitarbeiterId=${mitarbeiterId}&token=${token}" style="padding: 10px; background-color: green; color: white; text-decoration: none; margin-right: 10px;">Akzeptieren</a>
          <a href="${process.env.REACT_APP_SUPABASE_URL}/functions/v1/respond-to-termin?action=decline&terminId=${termin.id}&mitarbeiterId=${mitarbeiterId}&token=${token}" style="padding: 10px; background-color: red; color: white; text-decoration: none;">Ablehnen</a>
        </div>
      `;
    }));

    const emailContent = `
    <h1>Neue Bestellung eingegangen</h1>
    <p>Hallo ${mitarbeiter.vorname} ${mitarbeiter.nachname},</p>
    <p>Ein neuer Auftrag ist eingegangen. Bitte entscheide, ob du an folgenden Terminen Zeit hast:</p>
    <h2>Details:</h2>
   
    <ul>
      <li>Format: ${produkt.name}</li>
      <li>Dauer: ${produkt.dauer} Stunden</li>
    </ul>
    ${bestellungDetails}
    <h3>Kundendetails:</h3>
    <ul>
      <li>Einrichtung: ${kunde.einrichtung}</li>
      <li>Ansprechpartner:in: ${kunde.ansprechpartner}</li>
      <li>Adresse: ${kunde.adresse}, ${kunde.plz} ${kunde.ort}</li>
      <li>Telefon: ${kunde.telefon}</li>
      <li>E-Mail: ${kunde.email}</li>
    </ul>

    <h2>Terminvorschläge:</h2>
    ${terminButtons.join('')}

    <p><strong>Bitte gib zu allen Vorschlägen eine Rückmeldung</strong></p>
    
    </br>

    <p>Diese E-Mail wurde automatisch erstellt. Wende dich bei Fragen oder Problemen bitte an theresa.heyer@pforzheim.de</p>

    `;

    const { data, error } = await supabase.functions.invoke('send-email', {
      body: {
        to: mitarbeiter.email,
        subject: `Anfrage ${produkt.name} von ${kunde.ansprechpartner} (${terminvorschlaege.map(termin => moment.utc(termin.vorschlagdatetime).local().format('DD.MM.YYYY HH:mm')).join(', ')})`,
        html: emailContent,
      },
    });

    if (error) {
      throw error;
    }

    notify('E-Mail erfolgreich an Mitarbeiter gesendet', { type: 'success' });
    
  } catch (error) {
    console.error('Error sending email to mitarbeiter:', error);
    let errorMessage = 'Unknown error occurred';
    if (error instanceof Error) {
      errorMessage = error.message;
    }
    notify(`Fehler beim Senden der E-Mail an Mitarbeiter: ${errorMessage}`, { type: 'error' });

    // Insert into failed_emails table
    await supabase.from('failed_emails').insert([{
      bestellung_id: bestellungId,
      recipient_email: mitarbeiterEmail,
      error_message: errorMessage,
      // The rest of the fields use default values
    }]);
  }
};

export const sendCompletionEmail = async (
  dataProvider: DataProvider,
  notify: any,
  bestellungId: number
) => {
  try {
    // Fetch bestellung details
    const { data: bestellung } = await dataProvider.getOne('bestellung', { id: bestellungId });
    if (!bestellung) throw new Error('Bestellung not found');

    // Fetch product details
    const { data: produkt } = await dataProvider.getOne('produkt', { id: bestellung.produkt_id });
    if (!produkt) throw new Error('Produkt not found');

    // Fetch terminvorschlag with status 'akzeptiert'
    const { data: terminvorschlaege } = await dataProvider.getList('terminvorschlag', {
      filter: { bestellung_id: bestellungId, status: 'akzeptiert' },
      pagination: { page: 1, perPage: 1 },
      sort: { field: 'id', order: 'ASC' }
    });
    if (!terminvorschlaege || terminvorschlaege.length === 0) throw new Error('No accepted terminvorschlag found');
    const terminvorschlag = terminvorschlaege[0];

    // Fetch accepted mitarbeiters
    const { data: acceptedMitarbeiters } = await dataProvider.getList('relmitarbeiterstatus', {
      filter: { terminvorschlag_id: terminvorschlag.id, angenommen: true },
      pagination: { page: 1, perPage: 100 },
      sort: { field: 'id', order: 'ASC' }
    });

    for (const relation of acceptedMitarbeiters) {
      const { data: mitarbeiter } = await dataProvider.getOne('mitarbeiter', { id: relation.mitarbeiter_id });
      if (!mitarbeiter) continue;

      const stundenlohn = 50;
      const arbeitszeit = produkt.dauer;
      const arbeitslohn = stundenlohn * arbeitszeit;
      const gesamtBetrag = arbeitslohn + mitarbeiter.fahrtkosten;
      const umsatzsteuer = mitarbeiter.umsatzsteuer ? gesamtBetrag * 0.19 : 0;
      const gesamtMitUmsatzsteuer = gesamtBetrag + umsatzsteuer;

      const localDateTime = moment.utc(terminvorschlag.vorschlagdatetime).local().format('DD.MM.YYYY HH:mm');

      const emailContent = `
        <h1>Abrechnungsinformationen für die Veranstaltung</h1>
        <p>Liebe ${mitarbeiter.vorname} ${mitarbeiter.nachname},</p>
        <p>vielen Dank für die Durchführung der Veranstaltung am ${localDateTime} Uhr. Hier sind die Abrechnungsdetails für deine Rechnung:</p>
        
        <h2>Veranstaltungsdetails:</h2>
        <ul>
          <li>Produkt: ${produkt.name}</li>
          <li>Datum und Uhrzeit: ${localDateTime} Uhr</li>
          <li>Dauer: ${produkt.dauer} Stunden</li>
        </ul>

        <h2>Abrechnung:</h2>
        <ul>
          <li>Stundenlohn: ${stundenlohn.toFixed(2)}€</li>
          <li>Arbeitslohn: ${arbeitszeit.toFixed(2)} Stunden x ${stundenlohn.toFixed(2)}€ = ${arbeitslohn.toFixed(2)}€</li>
          <li>Fahrtkosten: ${mitarbeiter.fahrtkosten.toFixed(2)}€</li>
          <li>Zwischensumme: ${gesamtBetrag.toFixed(2)}€</li>
        </ul>

        ${mitarbeiter.umsatzsteuer ? `
        <p>Umsatzsteuer (19%): ${umsatzsteuer.toFixed(2)}€</p>
        <p><strong>Gesamtbetrag inkl. Umsatzsteuer: ${gesamtMitUmsatzsteuer.toFixed(2)}€</strong></p>
        ` : `
        <p><strong>Gesamtbetrag: ${gesamtBetrag.toFixed(2)}€</strong></p>
        <p>Hinweis: Keine Umsatzsteuer ausgewiesen, da du nicht umsatzsteuerpflichtig sind.</p>
        `}

        <p>Bitte stelle deine Rechnung entsprechend dieser Aufstellung.</p>
    
      `;

      // Send email via Supabase Edge Function
      const { error } = await supabase.functions.invoke('send-email', {
        body: {
          to: mitarbeiter.email,
          subject: `Abrechnungsinformationen für ${produkt.name} am ${localDateTime}`,
          html: emailContent,
        },
      });

      if (error) throw error;

      console.log(`Completion email sent to ${mitarbeiter.email}`);
    }

    notify('Completion emails sent to accepted Mitarbeiters', { type: 'success' });
  } catch (error) {
    console.error('Error sending completion emails:', error);
    notify('Error sending completion emails', { type: 'error' });
  }
};

export const sendCancellationEmails = async (
  dataProvider: DataProvider,
  notify: any,
  bestellungId: number,
  terminvorschlagId?: number
) => {
  try {
    console.log('Starting cancellation emails for bestellung:', bestellungId);

    // Fetch bestellung with related data
    const { data: bestellung } = await dataProvider.getOne('bestellung', { id: bestellungId });
    console.log('Bestellung data:', bestellung);

    if (!bestellung) throw new Error('Bestellung not found');

    // Fetch related data
    const [{ data: kunde }, { data: produkt }] = await Promise.all([
      dataProvider.getOne('kunde', { id: bestellung.kunde_id }),
      dataProvider.getOne('produkt', { id: bestellung.produkt_id })
    ]);

    console.log('Kunde and Produkt:', { kunde, produkt });

    if (!kunde || !produkt) throw new Error('Required data not found');

    // Fetch terminvorschlag if ID provided, otherwise find the matched one
    let acceptedTermin;
    if (terminvorschlagId) {
      const { data } = await dataProvider.getOne('terminvorschlag', { id: terminvorschlagId });
      acceptedTermin = data;
    } else {
      const { data: terminvorschlaege } = await dataProvider.getList('terminvorschlag', {
        filter: { bestellung_id: bestellungId, status: 'akzeptiert' },
        pagination: { page: 1, perPage: 1 },
        sort: { field: 'id', order: 'ASC' }
      });
      acceptedTermin = terminvorschlaege?.[0];
    }

    console.log('Found accepted termin:', acceptedTermin);

    // Format date if we have an accepted termin
    const formattedDate = acceptedTermin ? 
      moment.utc(acceptedTermin.vorschlagdatetime)
        .tz('Europe/Berlin')
        .format('DD.MM.YYYY, HH:mm') : 
      null;

    console.log('Formatted date:', formattedDate);

    // Send email to kunde
    const kundeEmailContent = `
      <h2>Stornierung: ${produkt.name}</h2>
      <p><strong>Einrichtung:</strong> ${kunde.einrichtung}</p>
      <h3>Bestellungsdetails:</h3>
      <ul>
        <li>Bestellungs-ID: ${bestellung.id}</li>
        <li>Produkt: ${produkt.name}</li>
        <li>Anzahl Teilnehmer: ${bestellung.anzahltn}</li>
        <li>Sonderwünsche: ${bestellung.sonderwuensche || 'Keine'}</li>
        ${formattedDate ? `<li>Gebuchter Termin: ${formattedDate}</li>` : ''}
      </ul>

      <h2>Wie geht es nun weiter?</h2>
      <p>Sollte die Stornierung nicht auf Ihren Wunsch erfolgt sein, stehen an den von Ihnen gewünschten Terminen leider nicht genügend museumspädagogische Fachkräfte zur Verfügung.</p>
      <p>Kontaktieren Sie uns bitte erneut wegen eines neuen Termins oder nutzen Sie unsere Online Terminbuchung</p>
      
      <p>Sollten Sie Fragen haben, zögern Sie bitte nicht, uns unter theresa.heyer@pforzheim.de zu kontaktieren.</p>
      <p>Mit freundlichen Grüßen,<br>Theresa Heyer (Beauftragte für Kulturelle Bildung)</p>
    `;

    // Send email to kunde
    await supabase.functions.invoke('send-email', {
      body: {
        to: kunde.email,
        subject: `Stornierung: ${produkt.name}${formattedDate ? ` (${formattedDate})` : ''}`,
        html: kundeEmailContent,
      },
    });

    // If we have an accepted termin, send emails to mitarbeiters
    if (acceptedTermin) {
      console.log('Fetching mitarbeiter relations for terminvorschlag:', acceptedTermin.id);
      const { data: relations } = await dataProvider.getList('relmitarbeiterstatus', {
        filter: { 
          terminvorschlag_id: acceptedTermin.id,
          bestaetigt: true
        },
        pagination: { page: 1, perPage: 1000 },
        sort: { field: 'id', order: 'ASC' }
      });

      console.log('Found mitarbeiter relations:', relations);

      // Send email to each accepted mitarbeiter
      for (const relation of relations) {
        const { data: mitarbeiter } = await dataProvider.getOne('mitarbeiter', { id: relation.mitarbeiter_id });
        console.log('Sending email to mitarbeiter:', mitarbeiter);
        
        if (!mitarbeiter) continue;

        const mitarbeiterEmailContent = `
          <p>Hallo ${mitarbeiter.vorname} ${mitarbeiter.nachname},</p>
          <p>Wir möchten dich darüber informieren, dass die folgende Bestellung storniert wurde:</p>
          <h2>Stornierung: ${produkt.name}</h2>
          <ul>
            <li>Bestellungs-ID: ${bestellung.id}</li>
            <li>Produkt: ${produkt.name}</li>
            <li>Gebuchter Termin: ${formattedDate}</li>
          </ul>
          <p>Bitte beachte, dass du für diesen Termin nicht mehr eingeplant bist.</p>
          <p>Bei Fragen stehen wir dir gerne zur Verfügung.</p>
        `;

        await supabase.functions.invoke('send-email', {
          body: {
            to: mitarbeiter.email,
            subject: `Stornierung: ${produkt.name} (${formattedDate})`,
            html: mitarbeiterEmailContent,
          },
        });

        // Wait 1 second between emails
        await new Promise(resolve => setTimeout(resolve, 1000));
      }
    }

    notify('Stornierungsemails wurden erfolgreich versendet', { type: 'success' });
  } catch (error) {
    console.error('Error in sendCancellationEmails:', error);
    throw error;
  }
};