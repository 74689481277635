// BestellungEdit.tsx
import React, { useEffect, useState } from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    ReferenceInput,
    SelectInput,
    NumberInput,
    ReferenceManyField,
    ReferenceField,
    useNotify,
    useRedirect,
    useRefresh,
    useUpdate,
    useRecordContext,
    useGetOne,
    useDataProvider,
    BooleanInput,
    EditActions,
    TopToolbar,
    ListButton,
    SaveButton
} from 'react-admin';
import { Typography, Grid, Dialog, DialogTitle, DialogContent, DialogActions, Button, CircularProgress, Checkbox, FormControlLabel } from '@mui/material';
import { useParams } from 'react-router-dom';
import TerminvorschlagDatagrid from './TerminvorschlagDatagrid';
import AddMitarbeiterButton from './AddMitarbeiterButton';
import axios from 'axios';
import moment from 'moment';
import { sendCancellationEmails } from './sendEmail';
import ArchiveButton from './ArchiveButton';

const BestellungEditForm: React.FC = () => {
    const notify = useNotify();
    const redirect = useRedirect();
    const refresh = useRefresh();
    const [update] = useUpdate();
    const { id } = useParams<{ id: string }>();
    const { data, isLoading } = useGetOne('bestellung', { id: id || '' });
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const dataProvider = useDataProvider();
    const [isCancelling, setIsCancelling] = useState<boolean>(false);
    const [isArchived, setIsArchived] = useState<boolean>(false);

    useEffect(() => {
        const checkBestellungStatus = async () => {
            if (data && id) {
                const createdAt = moment(data.created_at);
                const now = moment();
                const isOlderThan48Hours = now.diff(createdAt, 'hours') > 48;

                if (isOlderThan48Hours) {
                    try {
                        const { data: terminvorschlaege } = await dataProvider.getList('terminvorschlag', {
                            pagination: { page: 1, perPage: 1000 },
                            sort: { field: 'id', order: 'ASC' },
                            filter: { bestellung_id: id }
                        });

                        const hasAcceptedTermin = terminvorschlaege.some(tv => tv.status === "akzeptiert");

                        if (!hasAcceptedTermin) {
                            setOpenDialog(true);
                        }
                    } catch (error) {
                        console.error('Error fetching terminvorschlaege:', error);
                        notify('Error checking bestellung status', { type: 'error' });
                    }
                }
            }
        };

        checkBestellungStatus();
    }, [data, id, dataProvider, notify]);

    useEffect(() => {
        if (data) {
            setIsArchived(data.archived || false);
        }
    }, [data]);

    const handleCancelBestellung = async () => {
        if (!id) {
            notify('Invalid bestellung ID', { type: 'error' });
            return;
        }

        setIsCancelling(true);
        setOpenDialog(false);

        try {
            await update(
                'bestellung',
                { id, data: { status: 'storniert' } },
                { mutationMode: 'pessimistic' }
            );

            // Send cancellation emails
            await sendCancellationEmails(dataProvider, notify, parseInt(id));

            // Update terminvorschlag statuses
            const { data: terminvorschlaege } = await dataProvider.getList('terminvorschlag', {
                pagination: { page: 1, perPage: 1000 },
                sort: { field: 'id', order: 'ASC' },
                filter: { bestellung_id: id }
            });

            for (const terminvorschlag of terminvorschlaege) {
                await update(
                    'terminvorschlag',
                    { id: terminvorschlag.id, data: { status: 'abgelehnt' } },
                    { mutationMode: 'pessimistic' }
                );
            }

            notify('Bestellung storniert und E-Mails versendet.', { type: 'success' });
            redirect('/bestellung');
            refresh();
        } catch (error) {
            console.error('Error in cancellation process:', error);
            notify(`Fehler beim Stornieren: ${error instanceof Error ? error.message : 'Unbekannter Fehler'}`, { type: 'error' });
        } finally {
            setIsCancelling(false);
        }
    };

    const handleSubmit = async (values: any) => {
        if (!id) {
            notify('Invalid bestellung ID', { type: 'error' });
            return;
        }

        try {
            await update(
                'bestellung',
                { 
                    id, 
                    data: {
                        ...values,
                        archived: values.archived,
                        archived_at: values.archived ? new Date().toISOString() : null
                    }
                },
                {
                    onSuccess: async () => {
                        notify('Bestellung updated');
                        if (values.status === 'storniert') {
                            await handleCancelBestellung();
                        }
                        redirect('/bestellung');
                        refresh();
                    },
                    onError: error => {
                        console.error('Error updating bestellung:', error);
                        notify(`Update error: ${error instanceof Error ? error.message : 'Unbekannter Fehler'}`, { type: 'warning' });
                    },
                }
            );
        } catch (error) {
            console.error('Error in handleSubmit:', error);
            notify(`Submission error: ${error instanceof Error ? error.message : 'Unbekannter Fehler'}`, { type: 'error' });
        }
    };

    const handleCloseDialog = () => {
        if (!isCancelling) {
            setOpenDialog(false);
        }
    };

    const handleArchiveChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const newArchivedStatus = e.target.checked;
        setIsArchived(newArchivedStatus);
        
        try {
            await update(
                'bestellung',
                { 
                    id: id || '',
                    data: { 
                        archived: newArchivedStatus,
                        archived_at: newArchivedStatus ? new Date().toISOString() : null
                    }
                },
                {
                    mutationMode: 'pessimistic',
                    onSuccess: () => {
                        notify(newArchivedStatus ? 'Bestellung archiviert' : 'Bestellung wiederhergestellt', { type: 'success' });
                        refresh();
                    },
                    onError: error => {
                        setIsArchived(!newArchivedStatus); // Revert the checkbox if update fails
                        notify(`Update error: ${error instanceof Error ? error.message : 'Unbekannter Fehler'}`, { type: 'error' });
                    },
                }
            );
        } catch (error) {
            setIsArchived(!newArchivedStatus); // Revert the checkbox if update fails
            notify(`Error: ${error instanceof Error ? error.message : 'Unbekannter Fehler'}`, { type: 'error' });
        }
    };

    if (isLoading) return <div>Loading...</div>;
    if (!data) return <div>No data available</div>;

    return (
        <>
            <SimpleForm onSubmit={handleSubmit} record={data} toolbar={<SaveButton />}>
                <TextInput disabled source="id" />
                <ReferenceInput source="kunde_id" reference="kunde">
                    <SelectInput optionText="einrichtung" />
                </ReferenceInput>
                <ReferenceInput source="produkt_id" reference="produkt">
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <NumberInput source="anzahltn" />
                <TextInput multiline source="sonderwuensche" />
                <SelectInput
                    source="status"
                    choices={[
                        { id: 'neu', name: 'Neu' },
                        { id: 'machbar', name: 'Machbar' },
                        { id: 'abgeschlossen', name: 'Abgeschlossen' },
                        { id: 'storniert', name: 'Storniert' },
                    ]}
                />

                <Typography variant="h6" style={{ marginTop: '20px', marginBottom: '10px' }}>Produktdetails</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <ReferenceField label="Museum" source="produkt_id" reference="produkt" link={false}>
                            <ReferenceField source="museum_id" reference="museum" link={false}>
                                <TextInput source="name" disabled />
                            </ReferenceField>
                        </ReferenceField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <ReferenceField label="Dauer (Minuten)" source="produkt_id" reference="produkt" link={false}>
                            <NumberInput source="dauer" disabled />
                        </ReferenceField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <ReferenceField label="Kosten" source="produkt_id" reference="produkt" link={false}>
                            <NumberInput source="kosten" disabled />
                        </ReferenceField>
                    </Grid>
                    <Grid item xs={12}>
                        <ReferenceField label="Anzahl benötigter Mitarbeiter:innen" source="produkt_id" reference="produkt" link={false}>
                            <NumberInput source="anzahlmitarbeiter" disabled />
                        </ReferenceField>
                    </Grid>
                </Grid>

                <Typography variant="h6" style={{ marginTop: '20px', marginBottom: '10px' }}>Matching Tabelle</Typography>
                <ReferenceManyField label="Terminvorschläge" reference="terminvorschlag" target="bestellung_id">
                    <TerminvorschlagDatagrid />
                    <AddMitarbeiterButton />
                </ReferenceManyField>

                {data.archived && (
                    <>
                        <Typography variant="h6" style={{ marginTop: '20px', marginBottom: '10px' }}>Archivierung</Typography>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isArchived}
                                    onChange={handleArchiveChange}
                                    color="primary"
                                />
                            }
                            label="Bestellung archivieren"
                        />
                    </>
                )}
            </SimpleForm>
            <Dialog 
                open={openDialog && (!data || data.status !== 'storniert')}
                onClose={handleCloseDialog}
                disableEscapeKeyDown={isCancelling}
            >
                <DialogTitle>Bestellung älter als 48 Stunden ohne akzeptierten Termin</DialogTitle>
                <DialogContent>
                    Diese Bestellung ist älter als 48 Stunden und hat noch keinen akzeptierten Termin. Was möchten Sie tun?
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} disabled={isCancelling}>Bestellung offen lassen</Button>
                    <Button 
                        onClick={handleCancelBestellung} 
                        disabled={isCancelling}
                        startIcon={isCancelling ? <CircularProgress size={20} /> : null}
                    >
                        {isCancelling ? 'Stornierung läuft...' : 'Bestellung stornieren'}
                    </Button>
                    
                </DialogActions>
            </Dialog>
            {isCancelling && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 9999,
                }}>
                    <CircularProgress />
                </div>
            )}
        </>
    );
};

const BestellungEditActions = () => {
    const record = useRecordContext();
    return (
        <TopToolbar>
            {record && !record.archived && (
                <ArchiveButton />
            )}
        </TopToolbar>
    );
};

export const BestellungEdit: React.FC = () => (
    <Edit 
        actions={<BestellungEditActions />}
        disableDelete={true}
    >
        <BestellungEditForm />
    </Edit>
);

export default BestellungEdit;