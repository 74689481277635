import moment from 'moment-timezone';

// Set default configuration
moment.locale('de');
moment.tz.setDefault('Europe/Berlin');

export const formatToUTC = (date: string) => {
    return moment.tz(date, 'Europe/Berlin').utc().format();
};

export const formatFromUTC = (date: string) => {
    return moment.utc(date).tz('Europe/Berlin').format('DD.MM.YYYY HH:mm');
};

export const formatFromUTCToInput = (date: string) => {
    return moment.utc(date).tz('Europe/Berlin').format('YYYY-MM-DDTHH:mm');
};

export const formatDateTime = (date: string) => {
    return moment.utc(date).tz('Europe/Berlin').format('DD.MM.YYYY HH:mm:ss');
};
