import React, { useState } from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    ReferenceInput,
    SelectInput,
    NumberInput,
    useRecordContext,
    ArrayInput,
    SimpleFormIterator,
    BooleanInput,
} from 'react-admin';
import { Box, LinearProgress, Typography, Modal } from '@mui/material';
import { supabase } from '../supabaseClient';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import ProduktMitarbeiterAssociation from './ProduktMitarbeiterAssociation';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useDataProvider } from 'react-admin';

const ProduktTitle = () => {
    const record = useRecordContext();
    return <span>Produkt {record ? `"${record.name}"` : ''}</span>;
};

interface ImageUploadProps {
    productId: string | number;
    onUploadComplete: () => void;
}

const ImageUpload = ({ productId, onUploadComplete }: ImageUploadProps) => {
    const [uploadProgress, setUploadProgress] = useState(0);

    const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (!files) return;
        const file = files[0];
        if (!file) return;

        try {
            setUploadProgress(0);
            const fileName = `${productId}/${Date.now()}-${file.name}`;

            // Upload to Supabase Storage
            const { data: uploadData, error: uploadError } = await supabase.storage
                .from('product-images')
                .upload(fileName, file);

            if (uploadError) throw uploadError;

            // Save reference in database
            const { error: dbError } = await supabase
                .from('produkt_images')
                .insert({
                    produkt_id: productId,
                    storage_path: fileName,
                    display_order: 0
                });

            if (dbError) throw dbError;

            // Clear the progress and notify completion
            setUploadProgress(0);
            onUploadComplete();
        } catch (error) {
            console.error('Upload error:', error);
            setUploadProgress(0);
        }
    };

    return (
        <Box>
            <Button
                variant="contained"
                component="label"
                startIcon={<CloudUploadIcon />}
                sx={{ mb: 2 }}
            >
                Bild hochladen
                <input
                    hidden
                    accept="image/*"
                    type="file"
                    onChange={handleFileUpload}
                />
            </Button>
            {uploadProgress > 0 && (
                <Box sx={{ width: '100%', mt: 2 }}>
                    <LinearProgress variant="determinate" value={uploadProgress} />
                    <Typography variant="body2" color="text.secondary">
                        {Math.round(uploadProgress)}%
                    </Typography>
                </Box>
            )}
        </Box>
    );
};

interface ProductImage {
    id: number;
    storage_path: string;
    url: string;
    display_order: number;
    produkt_id: number;
}

const ImageGallery = ({ productId, className }: { productId: number; className?: string }) => {
    const [images, setImages] = useState<ProductImage[]>([]);
    const [selectedImage, setSelectedImage] = useState<string | null>(null);
    const dataProvider = useDataProvider();

    const loadImages = async () => {
        const { data, error } = await supabase
            .from('produkt_images')
            .select('*')
            .eq('produkt_id', productId)
            .order('display_order');

        if (error) {
            console.error('Error loading images:', error);
            return;
        }

        setImages(data.map(img => ({
            ...img,
            url: supabase.storage
                .from('product-images')
                .getPublicUrl(img.storage_path).data.publicUrl
        })));
    };

    const handleDelete = async (image: ProductImage) => {
        try {
            await dataProvider.deleteImage(image.id, image.storage_path);
            loadImages();
        } catch (error) {
            console.error('Error deleting image:', error);
        }
    };

    React.useEffect(() => {
        if (productId) loadImages();
    }, [productId]);

    // Only render if there are images
    if (images.length === 0) return null;

    return (
        <Box sx={{ mt: 2 }}>
            <Typography variant="h6">Bilder Galelrie</Typography>
            <ImageList sx={{ width: '100%', height: 200 }} cols={4} rowHeight={100}>
                {images.map((item) => (
                    <ImageListItem key={item.id}>
                        <img
                            src={item.url}
                            alt=""
                            loading="lazy"
                            style={{ cursor: 'pointer' }}
                            onClick={() => setSelectedImage(item.url)}
                        />
                        <IconButton
                            sx={{
                                position: 'absolute',
                                right: 4,
                                top: 4,
                                color: 'white',
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                padding: '4px',
                            }}
                            size="small"
                            onClick={() => handleDelete(item)}
                        >
                            <DeleteIcon fontSize="small" />
                        </IconButton>
                    </ImageListItem>
                ))}
            </ImageList>

            <Modal
                open={!!selectedImage}
                onClose={() => setSelectedImage(null)}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Box
                    component="img"
                    src={selectedImage || ''}
                    sx={{
                        maxHeight: '90vh',
                        maxWidth: '90vw',
                        objectFit: 'contain',
                    }}
                    onClick={() => setSelectedImage(null)}
                />
            </Modal>
        </Box>
    );
};

export const ProduktEdit = (props: any) => {
    const record = useRecordContext();
    
    return (
        <Edit {...props} title={<ProduktTitle />}>
            <SimpleForm>
                <TextInput disabled source="id" />
                <ReferenceInput source="museum_id" reference="museum">
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <TextInput source="name" />
                <TextInput
                    source="description"
                    multiline
                    rows={4}
                />
                <Box sx={{ my: 4 }}>
                    <Typography variant="h6" gutterBottom>Produktbilder</Typography>
                    <ImageGallerySection />
                </Box>
                <ArrayInput source="tags">
                    <SimpleFormIterator>
                        <TextInput source="" label="Tag" />
                    </SimpleFormIterator>
                </ArrayInput>
                <NumberInput source="dauer" />
                <NumberInput source="kosten" />
                <SelectInput source="fuereinrichtung" choices={[
                    { id: 'kita', name: 'Kita' },
                    { id: 'grundschule', name: 'Grundschule' },
                    { id: 'sek 1', name: 'Sek 1' },
                    { id: 'sek 2', name: 'Sek 2' },
                    { id: 'vabo', name: 'VABO' },
                    { id: 'vklgs', name: 'VKL Grundschule' },
                    { id: 'vksek1', name: 'VKL Sek 1' },
                    { id: 'andere', name: 'Andere' },
                ]} />
                <NumberInput source="maxtn" />
                <NumberInput source="anzahlmitarbeiter" />
                <BooleanInput
                    source="is_public"
                    label="Öffentlich"
                />
                <BooleanInput
                    source="archived"
                    label="Archiviert"
                />
                <ProduktMitarbeiterAssociation />
            </SimpleForm>
        </Edit>
    );
};

const ImageGallerySection = () => {
    const record = useRecordContext();
    const productId = record?.id;
    const [refreshTrigger, setRefreshTrigger] = useState(0);

    if (!productId) return null;

    return (
        <>
            <ImageUpload
                productId={productId}
                onUploadComplete={() => {
                    setRefreshTrigger(prev => prev + 1);
                }}
            />
            <ImageGallery
                productId={Number(productId)}
                key={refreshTrigger} // Force re-render on upload
                className="image-gallery"
            />
        </>
    );
};

export default ProduktEdit;