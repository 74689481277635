import React from 'react';
import { List, Datagrid, TextField, ReferenceField, NumberField, TextInput } from 'react-admin';
import ArchiveButton from './ArchiveButton';

const produktFilters = [
    <TextInput 
        label="Suche Produkt" 
        source="name_contains" 
        alwaysOn
        placeholder="Suche nach Produktname"
    />,
];

export const ProduktList = (props: any) => (
    <List {...props} filters={produktFilters} filter={{ archived: false }} resource="produkt">
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <ReferenceField source="museum_id" reference="museum">
                <TextField source="name" />
            </ReferenceField>
            <TextField source="name" />
            <NumberField source="dauer" />
            <NumberField source="kosten" options={{ style: 'currency', currency: 'EUR' }} />
            <TextField source="fuereinrichtung" />
            <NumberField source="maxtn" />
            <NumberField source="anzahlmitarbeiter" />
            <ArchiveButton resource="produkt" />
        </Datagrid>
    </List>
);

export default ProduktList;