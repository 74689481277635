import React from 'react';
import { 
    List, 
    Datagrid, 
    TextField, 
    ReferenceField, 
    NumberField, 
    TextInput,
    Empty  // Import the Empty component if you want to fully customize
} from 'react-admin';

// Option 1: Simple text override
export const ArchivedProduktList = () => (
    <List 
        filter={{ archived: true }}
        resource="produkt"
        empty={<div style={{ textAlign: 'center', padding: '2em' }}>Keine archivierten Produkte gefunden</div>}
    >
        <Datagrid>
            <TextField source="id" />
            <ReferenceField source="museum_id" reference="museum">
                <TextField source="name" />
            </ReferenceField>
            <TextField source="name" />
            <NumberField source="dauer" />
            <NumberField source="kosten" options={{ style: 'currency', currency: 'EUR' }} />
            <TextField source="fuereinrichtung" />
            <NumberField source="maxtn" />
            <NumberField source="anzahlmitarbeiter" />
        </Datagrid>
    </List>
);